import { useState, useEffect } from 'react'

import {
  QueryHookOptions,
  ApolloQueryResult,
  DocumentNode,
} from '@apollo/client'

import { useQuery as useBaseQuery } from '@redwoodjs/web'

interface UseQueryOptions<TData = any, TVariables = any>
  extends QueryHookOptions<TData, TVariables> {
  onCompleted?: (data: TData | {}) => void
}

interface UseQueryResult<TData, TVariables>
  extends QueryOperationResult<TData, TVariables> {
  hasLoaded: boolean
}

const useQuery = <TData = any, TVariables = any>(
  query: DocumentNode,
  options?: UseQueryOptions<TData, TVariables>
): UseQueryResult<TData, TVariables> => {
  const [hasLoaded, setHasLoaded] = useState(false)

  const result = useBaseQuery<TData, TVariables>(query, {
    ...options,
    onCompleted: (data) => {
      setHasLoaded(true)

      options?.onCompleted?.(data)
    },
  })

  useEffect(() => {
    if (result.error || result.data) {
      setHasLoaded(true)
    }
  }, [result.error, result.data])

  return { ...result, hasLoaded }
}

export default useQuery
