import { useLocation, useParams } from '@redwoodjs/router'
import { useOrgName } from 'src/hooks/useOrgName'
import { useAuth } from 'src/auth'
import useQuery from 'src/hooks/useQuery'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { CommentRoomType, COMMENT_ROOM_URL_MATCHERS, COMMENT_ROOM_TYPES, SUPPORT_USER_ID } from '@wingwork/common/src/constants/comments'
import getCommentRoomType from 'src/components/Comments/getCommentRoomType'
import Sentry from 'src/lib/sentry'
import { IN_PROGRESS_COMPLIANCE_STATUSES } from '@wingwork/common/src/constants'
import { appendTailNumber } from '@wingwork/common/src/helpers'
import { GET_USERS } from 'src/hooks/requests/useGetUsers'
import { createClient } from '@liveblocks/client'
import { createLiveblocksContext } from '@liveblocks/react'
import { CommentsProvider, DefaultCommentsProvider } from 'src/components/Comments/CommentContext'

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    allUsers {
      id
      firstName
      lastName
      clerkId
    }
  }
`

const GET_COMPLIANCE_DETAILS = gql`
  query GetComplianceNameById($id: String!) {
    complianceLedger(id: $id) {
      id
      ledgerNumber
      status
      aircraft {
        tailNumber
      }
      MaintenanceLogbook {
        id
      }
    }
  }
`

const GET_COMPLIANCE_LEDGER_ID_FROM_LOGBOOK_ENTRY_ID = gql`
  query GetComplianceLedgerIdFromLogbookEntryId($id: String!) {
    maintenanceLogbook(id: $id) {
      complianceLedgerId
    }
  }
`

const GET_PURCHASE_ORDER_NAME = gql`
  query GetPurchaseOrderName($id: String!) {
    purchaseOrder(id: $id) {
      number
    }
  }
`

const GET_WORK_ORDER_NAME = gql`
  query GetWorkOrderName($id: String!) {
    internalWorkOrder(id: $id) {
      name
      aircraft {
        tailNumber
      }
    }
  }
`

const GET_AIRCRAFT_NAME = gql`
  query GetAircraftNameById($id: String!) {
    aircraft(id: $id) {
      id
      tailNumber
    }
  }
`

const MAX_LIVEBLOCKS_AUTH_FAILURES = 4

type CustomCommentsProviderProps = {
  isAdmin?: boolean
}

/**
 * Sets up and returns a CommentsProvider with the correct auth calls
 * @param param0
 * @returns
 */
const useComments = ({ isAdmin = false }: CustomCommentsProviderProps) => {
  // TODO: would be nice to filter this in some way
  const { data: users } = useQuery(isAdmin ? GET_ALL_USERS : GET_USERS)

  const orgSlug = useOrgName()
  const { getToken } = useAuth()
  const params = useParams()
  const { pathname } = useLocation()
  const [roomId, setRoomId] = useState<string>()
  const [liveblocksAuthFailures, setLiveblocksAuthFailures] = useState(0)
  const [getComplianceLedgerDetails] = useLazyQuery(GET_COMPLIANCE_DETAILS)
  const [getComplianceLedgerIdFromLogbookEntryId] = useLazyQuery(GET_COMPLIANCE_LEDGER_ID_FROM_LOGBOOK_ENTRY_ID)
  const [getPurchaseOrderName] = useLazyQuery(GET_PURCHASE_ORDER_NAME)
  const [getWorkOrderName] = useLazyQuery(GET_WORK_ORDER_NAME)
  const [getAircraftName] = useLazyQuery(GET_AIRCRAFT_NAME)

  const [provider, setProvider] = useState<CommentsProvider>(() => DefaultCommentsProvider)

  const cleanedUsers = useMemo(() => (
    ((isAdmin ? users?.allUsers : users?.users) ?? []).map((user) => ({
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
    }))
  ), [users])

  const getRoomId = async (pathname: string, orgSlug: string) => {
    // Document hub changes for each aircraft, but the comments should always be in the same room since, from the users perspective, the page doesn't change
    if (pathname.match(COMMENT_ROOM_URL_MATCHERS.documentHub)) {
      return `/${orgSlug}/document-hub`
    }
    // Carry over comments made when creating a compliance to the created compliance
    if (pathname.match(COMMENT_ROOM_URL_MATCHERS.ledgerLogbookEntry)) {
      return `/${orgSlug}/compliance/${params.ledgerId}`
    }
    // Comments exist on the compliance ledger rather than the LBE generated from it
    if (pathname.match(COMMENT_ROOM_URL_MATCHERS.logbookEntry)) {
      const { data } = await getComplianceLedgerIdFromLogbookEntryId({ variables: { id: params.entryId } })
      if (!data?.maintenanceLogbook?.complianceLedgerId) {
        return pathname
      }
      return `/${orgSlug}/compliance/${data?.maintenanceLogbook?.complianceLedgerId}`
    }
    // If on the compliance ledger sign page, return the url of the main compliance ledger page
    if (pathname.match(COMMENT_ROOM_URL_MATCHERS.compliance)) {
      return `/${orgSlug}/compliance/${params.ledgerId}`
    }
    return pathname
  }

  useEffect(() => {
    getRoomId(pathname, orgSlug).then(setRoomId)
  }, [pathname, orgSlug])

  const resolveLedgerRoomName = async (roomId: string) => {
    const ledgerId = roomId.replace(new RegExp(`/${orgSlug}/[^/]+/`), '')
    const { data } = await getComplianceLedgerDetails({ variables: { id: ledgerId } })

    if (
      (IN_PROGRESS_COMPLIANCE_STATUSES.includes(data?.complianceLedger?.status) || !data?.complianceLedger?.MaintenanceLogbook?.length) &&
      data?.complianceLedger?.ledgerNumber
    ) {
      return `Compliance ${appendTailNumber(data?.complianceLedger?.ledgerNumber, data?.complianceLedger?.aircraft?.tailNumber)}`
    }
    if (data?.complianceLedger?.MaintenanceLogbook?.length && data?.complianceLedger?.aircraft?.tailNumber) {
      return `Logbook Entry for ${data?.complianceLedger?.aircraft?.tailNumber}`
    }
    return 'Compliance'
  }

  const commentRoomNameResolvers: { [key in CommentRoomType]: (roomId: string) => Promise<string> } = {
    fleetDashboard: () => Promise.resolve('Fleet Dashboard'),
    aircraftDashboard: async (roomId: string) => {
      const aircraftId = roomId.replace(`/${orgSlug}/aircraft/`, '')
      const { data } = await getAircraftName({ variables: { id: aircraftId } })
      return `Aircraft ${data?.aircraft?.tailNumber ?? ''} Dashboard`
    },
    reports: () => Promise.resolve('Reports'),
    documentHub: () => Promise.resolve('Document Hub'),
    dueList: () => Promise.resolve('Due List'),
    maintenanceItems: () => Promise.resolve('Maintenance Items'),
    compliance: resolveLedgerRoomName,
    workCompleted: () => Promise.resolve('Past Compliance'),
    ledgerLogbookEntry: resolveLedgerRoomName,
    logbookEntry: resolveLedgerRoomName,
    logbookEntries: () => Promise.resolve('Logbook Entries'),
    activityLog: () => Promise.resolve('Activity Log'),
    purchaseOrder: () => Promise.resolve('Purchase Orders'),
    purchaseOrders: async (roomId: string) => {
      const poId = roomId.replace(`/${orgSlug}/purchase-order/`, '')
      const { data } = await getPurchaseOrderName({ variables: { id: poId } })
      return `Purchase Order ${data?.purchaseOrder?.number ?? ''}`
    },
    workOrder: async (roomId: string) => {
      const workOrderId = roomId.replace(`/${orgSlug}/work-order/`, '')
      const { data } = await getWorkOrderName({ variables: { id: workOrderId } })
      return `Work Order ${appendTailNumber(data?.internalWorkOrder?.name ?? '', data?.internalWorkOrder?.aircraft?.tailNumber ?? '')}`
    },
    myWorkOrders: () => Promise.resolve('My Work Orders'),
    scheduledWorkOrders: () => Promise.resolve('Scheduled Work Orders'),
    completedWorkOrders: () => Promise.resolve('Completed Work Orders'),
    invoice: () => Promise.resolve('Invoice'),
  }

  if (COMMENT_ROOM_TYPES.find(commentRoomType => !commentRoomNameResolvers[commentRoomType] || !COMMENT_ROOM_URL_MATCHERS[commentRoomType])) {
    throw new Error(`Unknown comment room type: ${pathname}!  To fix this error, add a resolver to useComments() and a url matcher to the COMMENT_ROOM_URL_MATCHERS definition`)
  }

  const getAuth = useCallback((async (room: string) => {
    if (liveblocksAuthFailures >= MAX_LIVEBLOCKS_AUTH_FAILURES) {
      return Promise.resolve({ error: 'forbidden', reason: 'Liveblocks is not initialized' })
    }
    try {
      const token = await getToken()
      const headers = {
        orgSlug,
        Authorization: `Bearer ${token}`,
        'auth-provider': 'clerk',
      };

      const body = JSON.stringify({ room });

      if (isAdmin) {
        const response = await fetch("/.netlify/functions/liveblocksAdminAuth", { method: 'POST', headers, body })
        return await response.json()
      }

      const response = await fetch("/.netlify/functions/liveblocksAuth", { method: 'POST', headers, body })
      return await response.json()
    }
    catch (err) {
      setLiveblocksAuthFailures((prevLiveblocksAuthFailures) => prevLiveblocksAuthFailures + 1)
      return Promise.resolve({ error: 'forbidden', reason: 'Liveblocks is not initialized' })
    }
  }), [])

  const resolveUsers = useCallback(({ userIds }: { userIds: string[] }) => {
    return userIds.map((userId) => userId === SUPPORT_USER_ID ? { id: SUPPORT_USER_ID, name: 'WingWork Support' } : cleanedUsers.find((user) => user.id === userId))
  }, [cleanedUsers])

  const resolveMentionSuggestions = useCallback(({ text }: { text: string }) => {
    const userIds = cleanedUsers.filter((user) => user.name.toLowerCase().includes(text.toLowerCase())).map((user) => user.id)
    if ('wingwork support'.includes(text.toLowerCase()) || text.toLowerCase() === 'help') {
      userIds.push(SUPPORT_USER_ID)
    }
    return userIds
  }, [cleanedUsers])

  const resolveRoomsInfo = useCallback(({ roomIds }: { roomIds: string[] }) => {
    return Promise.all(roomIds.map(async (roomId) => {
      const roomType = getCommentRoomType(roomId, orgSlug)
      if (!roomType) {
        Sentry.captureException(`Unknown comment room type: ${roomId}!!  Ignoring this error will result in missing room/page names in comment notifications`)
      }
      return {
        url: roomId,
        id: roomId,
        name: await commentRoomNameResolvers[roomType]?.(roomId),
      }
    }))
  }, [orgSlug])

  useEffect(() => {
    const client = createClient({
      authEndpoint: getAuth,
      resolveUsers,
      resolveMentionSuggestions,
      resolveRoomsInfo,
    })
    const { LiveblocksProvider } = createLiveblocksContext(client);
    setProvider(() => LiveblocksProvider)
  }, [getAuth, resolveUsers, resolveMentionSuggestions, resolveRoomsInfo])

  if (!roomId || liveblocksAuthFailures > MAX_LIVEBLOCKS_AUTH_FAILURES) {
    return {
      CommentsProvider: DefaultCommentsProvider,
    }
  }

  return {
    CommentsProvider: provider,
    roomId,
  }
}

export default useComments
