import MuiCommentIcon from '@mui/icons-material/CommentOutlined'
import { useInboxNotifications, useRoom } from "@liveblocks/react/suspense";
import { Badge, IconButton, Tooltip } from '@mui/material';
import { useDispatch } from 'src/hooks/useDispatch';
import { commentsDrawerClosed, commentsDrawerExpandedToggled, commentsDrawerOpened, selectCommentsDrawerExpanded } from 'src/slices/appLayoutSlice';
import { navigate, useLocation } from '@redwoodjs/router';
import { COMMENT_HASH_REGEX } from '@wingwork/common/src/constants/comments';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useOrgName } from 'src/hooks/useOrgName';
import getCommentRoomType from 'src/components/Comments/getCommentRoomType';
import useHasFeature from 'src/hooks/useHasFeature';

const CommentIconContent = () => {
  const { inboxNotifications } = useInboxNotifications();
  const room = useRoom()
  const orgSlug = useOrgName()
  const { hasFeature: orgHasComments } = useHasFeature('Comments', orgSlug)
  const dispatch = useDispatch()
  const commentsDrawerExpanded = useSelector(selectCommentsDrawerExpanded)
  const { hash, pathname } = useLocation()

  const pageHasComments = !!getCommentRoomType(pathname, orgSlug)
  const commentsEnabled = orgHasComments && pageHasComments

  const unreadNotifications = inboxNotifications.filter((n) => !n.readAt && n.roomId === room?.id)

  useEffect(() => {
    if (unreadNotifications.length > 0) {
      dispatch(commentsDrawerOpened())
    }
  }, [unreadNotifications.length])

  // If comments are not enabled (likely because of org), close the comments drawer
  useEffect(() => {
    if (!commentsEnabled) {
      dispatch(commentsDrawerClosed())
    }
  }, [commentsEnabled])

  // If there's a comment ID in the url hash, open the comments drawer
  useEffect(() => {
    if (hash && hash.match(COMMENT_HASH_REGEX) && commentsEnabled) {
      dispatch(commentsDrawerOpened())
    }
  }, [hash, commentsEnabled])

  const onToggleClicked = () => {
    // When on a link that goes directly to a comment, remove the comment ID hash when closing the drawer, otherwise we see
    // weird UI behavior when trying to link back to the comment
    if (commentsDrawerExpanded && hash.match(COMMENT_HASH_REGEX)) {
      navigate(pathname, { replace: true })
    }
    dispatch(commentsDrawerExpandedToggled())
  }

  if (!orgHasComments) {
    return null
  }

  return <Tooltip title={pageHasComments ? '' : 'Comments are disabled on this page'}>
    <span>
      <IconButton
        aria-label="open comments drawer"
        color="inherit"
        disabled={!pageHasComments}
        onClick={onToggleClicked}
      >
        <Badge badgeContent={unreadNotifications.length} color="error">
          <MuiCommentIcon />
        </Badge>
      </IconButton>
    </span>
  </Tooltip >
}

export default CommentIconContent
