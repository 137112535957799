import CircleIcon from '@mui/icons-material/Circle'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import LoadingButton from '@mui/lab/LoadingButton'
import { IconButton, Menu, MenuItem, styled, Typography } from '@mui/material'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'

import { navigate } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { CheckOutlined, DeleteOutline } from '@mui/icons-material'
import { Timestamp } from '@liveblocks/react-ui'

const MARK_NOTIFICATION_AS_READ = gql`
  mutation markReadStatus($id: String!, $read: Boolean) {
    updateNotification(id: $id, input: { read: $read }) {
      id
      read
    }
  }
`

const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($id: String!) {
    deleteNotification(id: $id) {
      id
    }
  }
`

const PERFORM_NOTIFICATION_ACTION = gql`
  mutation PerformNotificationAction($id: String!) {
    performNotificationAction(id: $id) {
      success
      notification {
        id
        read
        actionPerformed
      }
    }
  }
`

const StyledMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
  },
  '& .MuiList-root': {
    padding: 0,
  },
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '.iconWrapperDiv': {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  '& .MuiSvgIcon-root': {
    width: 20,
    height: 20,
    fontSize: 20,
    color: theme.palette.text.lowEmpathy,
  }
}));

const StyledTimestamp = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.lowEmpathy,
  fontWeight: 400,
  whiteSpace: 'nowrap',
}));

export const unreadNotificationBackground = 'bg-blue-50'

const Notification = ({ notification, handleClose, refetch }) => {
  const { t } = useTranslation()
  const [isHovering, setIsHovering] = React.useState(false)
  const [showMoreMenuEl, setShowMoreMenuEl] = React.useState<HTMLButtonElement | null>(
    null
  )
  const [markReadStatus] = useMutation(MARK_NOTIFICATION_AS_READ)
  const [deleteNotification] = useMutation(DELETE_NOTIFICATION)
  const [performAction, { loading: performActionLoading }] = useMutation(
    PERFORM_NOTIFICATION_ACTION
  )

  const handleClick = () => {
    if (!notification.link) {
      return;
    }
    if (!notification.read) {
      markReadStatus({ variables: { id: notification.id, read: true } })
    }
    if (notification.link) {
      navigate(notification.link)
      handleClose()
    }
  }

  const handleActionClick = async (e) => {
    e.stopPropagation()
    performAction({ variables: { id: notification.id } })
  }

  const handleMenuClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setShowMoreMenuEl(e.currentTarget)
  }

  const handleMarkAsRead = (e: React.MouseEvent<HTMLLIElement>) => {
    markReadStatus({
      variables: { id: notification.id, read: !notification.read },
    })
    refetch()
    handleMoreClose(e)
  }

  const handleDeleteNotification = (e: React.MouseEvent<HTMLLIElement>) => {
    deleteNotification({
      variables: { id: notification.id },
    })
    refetch()
    handleMoreClose(e)
  }

  const handleMoreClose = (e: React.MouseEvent<HTMLLIElement>) => {
    setShowMoreMenuEl(null)
    e.stopPropagation()
  }

  return (
    <>
      {/* eslint-disable-next-line */}
      <div
        key={notification.id}
        className={clsx("flex w-full flex-col justify-between rounded-md p-2 duration-200 ease-in-out", {
          [unreadNotificationBackground]: !notification.read,
          'cursor-pointer': notification.link
        })}
        onClick={handleClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className="flex items-start justify-between gap-2">
          <div className="flex flex-col w-4/5">
            <Markdown components={{ p: 'span' }} className="line-clamp-3">
              {notification.message.replace(/\n/g, '  \n')}
            </Markdown>
          </div>

          <div className="flex items-center justify-end gap-1">
            {isHovering || !!showMoreMenuEl
              ? <IconButton
                size="small"
                onClick={handleMenuClicked}
              >
                <MoreHorizIcon fontSize="small" />
              </IconButton>
              : (
                <>
                  <StyledTimestamp variant="subtitle2">
                    <Timestamp date={notification.createdAt} />
                  </StyledTimestamp>
                  {!notification.read && <CircleIcon
                    fontSize="small"
                    width={20}
                    height={20}
                    style={{ fontSize: 12 }}
                    className="text-blue-500 transition-colors duration-100 ease-in-out"
                  />}
                </>)
            }
          </div>
        </div>
        {notification.actionName && (
          <LoadingButton
            fullWidth
            onClick={handleActionClick}
            disabled={notification.actionPerformed}
            loading={performActionLoading}
            loadingPosition="end"
          >
            {t(`notification.actionTitle.${notification.actionName}`, {
              defaultValue: notification.actionName,
            })}
          </LoadingButton>
        )}
      </div>
      <StyledMenu
        id="show-more-menu"
        className="p-0"
        anchorEl={showMoreMenuEl}
        open={!!showMoreMenuEl}
        onClose={handleMoreClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuItem
          dense
          className="p-1 justify-start"
          onClick={handleMarkAsRead}
        >
          <div className="iconWrapperDiv"><CheckOutlined /></div>
          <Typography variant="body2">{!notification.read ? 'Mark as read' : 'Mark as unread'}</Typography>
        </StyledMenuItem>
        <StyledMenuItem
          dense
          className="p-1 justify-start"
          onClick={handleDeleteNotification}
        >
          <div className="iconWrapperDiv"><DeleteOutline /></div>
          <Typography variant="body2">Delete notification</Typography>
        </StyledMenuItem>
      </StyledMenu>
    </>
  )
}

export default Notification
