import { createTheme, alpha, getContrastRatio } from '@mui/material'
import { PaletteColor, PaletteColorOptions } from '@mui/material/styles'

export const tabletMediaQuery = '@media (max-width:1200px)'

const pastelRedBase = '#e53935'
const pastelRedMain = alpha(pastelRedBase, 0.7)

declare module '@mui/material/styles' {
  interface Palette {
    base?: PaletteColor
    pastelRed?: PaletteColor
    // Add other custom colors here
  }
  interface PaletteOptions {
    base?: PaletteColorOptions
    pastelRed?: PaletteColorOptions
    white?: PaletteColorOptions
    // Add other custom colors here
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    base: true
    pastelRed: true
    white: true
    // Add other custom colors here
  }
}

const palette = {
  white: {
    main: '#FFFFFF',
    light: '#FFFFFF',
    dark: '#F0F0F0',
    contrastText: '#000000',
  },
  primary: {
    main: '#0573ED',
    primary50: '#0573ED',
  },
  sys: {
    black: '#000000',
    white: '#FFFFFF',
    outline: '#C4C6D0',
    lightgrey: '#F5F5F5',
  },
  neutral: {
    neutral40: '#5E5E62',
  },
  text: {
    main: '#000000',
    lowEmpathy: '#9E9E9E',
  },
  base: {
    main: '#C4C6D0',
    contrastText: '#5E5E62',
  },
  semantic: {
    warning90: '#E77D2B',
    error90: '#C62F2F',
    success90: '#25C131',
  },
  pastelRed: {
    light: alpha(pastelRedBase, 0.5),
    main: pastelRedMain,
    dark: alpha(pastelRedBase, 0.9),
    contrastText:
      getContrastRatio(pastelRedMain, '#FFFFFF') > 4 ? '#FFFFFF' : '#00000042',
  },
}

const getTypographyVariant = (desktopSpecs, tabletSpecs) => ({
  fontWeight: desktopSpecs.fontWeight,
  fontSize: desktopSpecs.fontSize,
  lineHeight: desktopSpecs.lineHeight,
  [tabletMediaQuery]: {
    // Tablet adjustments
    fontSize: tabletSpecs.fontSize,
    lineHeight: tabletSpecs.lineHeight,
    fontWeight: tabletSpecs.fontWeight,
  },
})

const theme = createTheme({
  palette: palette,
  breakpoints: {
    values: {
      xs: 0, // phones
      sm: 600, // tablets
      md: 900, // small laptop screens
      lg: 1200, // desktops
      xl: 1536, // large screens
    },
  },
  typography: {
    h1: getTypographyVariant(
      { fontSize: '5.875rem', fontWeight: 300, lineHeight: '7.625rem' },
      { fontSize: '5.625rem', fontWeight: 300, lineHeight: '6.5rem' }
    ),
    h2: getTypographyVariant(
      { fontSize: '3.6875rem', fontWeight: 300, lineHeight: '4.875rem' },
      { fontSize: '3rem', fontWeight: 300, lineHeight: '4rem' }
    ),
    h3: getTypographyVariant(
      { fontSize: '2.9375rem', fontWeight: 400, lineHeight: '3.75rem' },
      { fontSize: '2.25rem', fontWeight: 400, lineHeight: '3rem' }
    ),
    h4: getTypographyVariant(
      { fontSize: '2.5rem', fontWeight: 500, lineHeight: '3rem' },
      { fontSize: '2.25rem', fontWeight: 500, lineHeight: '2.5rem' }
    ),
    h5: getTypographyVariant(
      { fontSize: '2rem', fontWeight: 500, lineHeight: '2.5rem' },
      { fontSize: '1.75rem', fontWeight: 500, lineHeight: '2rem' }
    ),
    h6: getTypographyVariant(
      { fontSize: '1.25rem', fontWeight: 500, lineHeight: '1.5rem' },
      { fontSize: '1.125rem', fontWeight: 500, lineHeight: '1.5rem' }
    ),
    subtitle1: getTypographyVariant(
      { fontSize: '1rem', fontWeight: 500, lineHeight: '1.75rem' },
      { fontSize: '1rem', fontWeight: 500, lineHeight: '1.5rem' }
    ),
    subtitle2: getTypographyVariant(
      { fontSize: '0.875rem', fontWeight: 500, lineHeight: '1.375rem' },
      { fontSize: '0.875rem', fontWeight: 500, lineHeight: '1.125rem' }
    ),
    body1: getTypographyVariant(
      { fontSize: '1rem', fontWeight: 400, lineHeight: '1.5rem' },
      { fontSize: '0.875rem', fontWeight: 400, lineHeight: '1.25rem' }
    ),
    body2: getTypographyVariant(
      { fontSize: '0.875rem', fontWeight: 400, lineHeight: '1.25rem' },
      { fontSize: '0.75rem', fontWeight: 400, lineHeight: '1rem' }
    ),
    button: getTypographyVariant(
      { fontSize: '0.875rem', fontWeight: 500, lineHeight: '1.125rem' },
      { fontSize: '0.75rem', fontWeight: 500, lineHeight: '1rem' }
    ),
    caption: getTypographyVariant(
      { fontSize: '0.75rem', fontWeight: 400, lineHeight: '1rem' },
      { fontSize: '0.625rem', fontWeight: 400, lineHeight: '0.875rem' }
    ),
    overline: getTypographyVariant(
      { fontSize: '0.75rem', fontWeight: 400, lineHeight: '1rem' },
      { fontSize: '0.625rem', fontWeight: 400, lineHeight: '0.875rem' }
    ),
  },
  zIndex: {
    drawer: 1201, // Set a higher zIndex value for the drawer
    appBar: 1200, // Set a lower zIndex value for the appBar/navbar
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'normal',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top',
      },
    },
    MuiButton: {
      defaultProps: { variant: 'contained' },
      styleOverrides: {
        root: { textTransform: 'none' },
        outlinedBase: {
          // required to override the default text color
          color: palette.base.contrastText,
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: { variant: 'contained' },
      styleOverrides: {
        root: { textTransform: 'none' },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          color: palette.text.lowEmpathy,
        },
      },
    },
  },
})

export default theme
