import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useInboxNotifications, useMarkAllInboxNotificationsAsRead } from "@liveblocks/react/suspense";
import { Badge, Button, IconButton, Popover, Typography } from '@mui/material';
import NotificationsList from 'src/components/Notifications/NotificationsList';
import { routes } from '@redwoodjs/router';
import { useUnreadInboxNotificationsCount } from '@liveblocks/react';
import { useEffect, useMemo } from 'react';
import { useRef } from 'react';
import isThreadNotification from 'src/components/Notifications/helpers/isThreadNotification';
import { toast } from '@redwoodjs/web/toast';
import ThreadNotificationToast from 'src/components/Notifications/ThreadNotificationToast';
import getNewNotifications from 'src/components/Notifications/helpers/getNewNotifications';

const SupportNotificationPopover = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const { inboxNotifications } = useInboxNotifications();
  const markAllInboxNotificationsAsRead = useMarkAllInboxNotificationsAsRead()
  const { count: unreadNotificationsCount } = useUnreadInboxNotificationsCount()

  const notifications = useMemo(() => inboxNotifications?.filter(isThreadNotification)?.filter((n) => n.kind === 'thread').map((n) => ({ ...n, link: routes.adminSupport() })), [inboxNotifications])
  const previousNotifications = useRef(notifications)

  useEffect(() => {
    const processCommentThreadNotifications = async () => {
      // For any new notifications, show the toast
      if (previousNotifications.current) {
        const newNotifications = getNewNotifications(previousNotifications.current, notifications)
        for (const notification of newNotifications) {
          toast.custom(
            <ThreadNotificationToast
              notification={notification}
              onClose={() => toast.remove(notification.id)}
              isAdmin
            />,
            { id: notification.id } // For some reason, toast.dismiss was not removing the last toast, so I had to use toast.remove
          )
        }
      }

      previousNotifications.current = notifications
    }
    if (notifications) {
      processCommentThreadNotifications()
    }
  }, [notifications])

  const handleIconClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-label="go to support page"
        color="inherit"
        onClick={handleIconClick}
      >
        <Badge badgeContent={unreadNotificationsCount} color="error">
          <SupportAgentIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper:
            'flex flex-col rounded-md w-46 max-h-45 mt-0.5 overflow-hidden',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="m-1 flex items-center justify-between">
          <Typography variant="h6">Support Notifications</Typography>
          <Button
            size="small"
            variant="text"
            onClick={markAllInboxNotificationsAsRead}
          >
            Mark all as read
          </Button>
        </div>
        {notifications.length === 0 && (
          <div className="flex h-full items-center justify-center">
            <Typography variant="subtitle2" className="my-4">
              No new notifications
            </Typography>
          </div>
        )}
        <NotificationsList notifications={notifications} refetch={() => { }} handleNotificationsClose={handleClose} />
      </Popover>
    </>
  )
}

export default SupportNotificationPopover
