import { QueryHookOptions } from '@apollo/client'

import { useOrgName } from '../useOrgName'
import useQuery from '../useQuery'
import { User } from 'types/graphql'

// TODO: we need to add a org param, and maybe a role param
export const GET_USERS = gql`
  query getUsers($filter: UserFilter) {
    users(filter: $filter) {
      id
      firstName
      lastName
      clerkId
      membership {
        userRole
      }
    }
  }
`

const useGetUsers = (options?: QueryHookOptions) => {
  const orgName = useOrgName()
  options = {
    fetchPolicy: 'cache-first',
    ...options,
    variables: {
      filter: {
        orgSlug: orgName,
        ...options?.variables?.filter,
      },
    },
  }
  return useQuery<{ users: User[] }>(GET_USERS, options)
}

export default useGetUsers
