export const ADMIN_ROLE = 'ww-user'

export const Permissions = {
  compliance: {
    create: { action: 'create', resource: 'compliance' } as const,
    read: { action: 'read', resource: 'compliance' } as const,
    update: { action: 'update', resource: 'compliance' } as const,
    complete: { action: 'complete', resource: 'compliance' } as const,
    actAsTechnician: {
      action: 'act-as-technician',
      resource: 'compliance',
    } as const,
    actAsInspector: {
      action: 'act-as-inspector',
      resource: 'compliance',
    } as const,
  },
  maintenanceItem: {
    create: { action: 'create', resource: 'maintenance-item' } as const,
    read: { action: 'read', resource: 'maintenance-item' } as const,
    update: { action: 'update', resource: 'maintenance-item' } as const,
    delete: { action: 'delete', resource: 'maintenance-item' } as const,
    modifyInterval: {
      action: 'modify-interval',
      resource: 'maintenance-item',
    } as const,
    modifyNextDueValue: {
      action: 'modify-next-due-value',
      resource: 'maintenance-item',
    } as const,
    modifyRate: {
      action: 'modify-rate',
      resource: 'maintenance-item',
    } as const,
    modifyWorkHours: {
      action: 'modify-work-hours',
      resource: 'maintenance-item',
    } as const,
  },
  user: {
    add: { action: 'add', resource: 'user' } as const,
    grantPermission: { action: 'grant-permission', resource: 'user' } as const,
    grantRole: { action: 'grant-role', resource: 'user' } as const,
    remove: { action: 'remove', resource: 'user' } as const,
    revokePermission: {
      action: 'revoke-permission',
      resource: 'user',
    } as const,
    revokeRole: { action: 'revoke-role', resource: 'user' } as const,
  },
  workOrder: {
    create: { action: 'create', resource: 'work-order' } as const,
    read: { action: 'read', resource: 'work-order' } as const,
    update: { action: 'update', resource: 'work-order' } as const,
    performWorkItem: {
      action: 'perform-work-item',
      resource: 'work-order',
    } as const,
    addTasks: { action: 'add-tasks', resource: 'work-order' } as const,
    removeTasks: { action: 'remove-tasks', resource: 'work-order' } as const,
    complete: { action: 'complete', resource: 'work-order' } as const,
    actAsInspector: {
      action: 'act-as-inspector',
      resource: 'work-order',
    } as const,
    actAsTechnician: {
      action: 'act-as-technician',
      resource: 'work-order',
    } as const,
    delete: { action: 'delete', resource: 'work-order' } as const,
    assignMechanic: { action: 'assign-mechanic', resource: 'work-order' } as const,
  },
  purchaseOrder: {
    create: { action: 'create', resource: 'purchase-order' } as const,
    addItems: { action: 'add-items', resource: 'purchase-order' } as const,
    deleteItems: { action: 'delete-items', resource: 'purchase-order' } as const,
    cancel: { action: 'cancel', resource: 'purchase-order' } as const,
    complete: { action: 'complete', resource: 'purchase-order' } as const,
    update: { action: 'update', resource: 'purchase-order' } as const,
    read: { action: 'read', resource: 'purchase-order' } as const,
    delete: { action: 'delete', resource: 'purchase-order' } as const,
    rejectItems: { action: 'reject-items', resource: 'purchase-order' } as const,
    requestItems: { action: 'request-items', resource: 'purchase-order' } as const,
  },
}

type CompliancePermission =
  (typeof Permissions.compliance)[keyof typeof Permissions.compliance]

type MaintenanceItemPermission =
  (typeof Permissions.maintenanceItem)[keyof typeof Permissions.maintenanceItem]

type UserPermission = (typeof Permissions.user)[keyof typeof Permissions.user]

type WorkOrderPermission =
  (typeof Permissions.workOrder)[keyof typeof Permissions.workOrder]

type PurchaseOrderPermission =
  (typeof Permissions.purchaseOrder)[keyof typeof Permissions.purchaseOrder]

export type OrgPermissions =
  | CompliancePermission
  | MaintenanceItemPermission
  | UserPermission
  | WorkOrderPermission
  | PurchaseOrderPermission
