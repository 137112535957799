import { ClientSideSuspense } from "@liveblocks/react"
import { ErrorBoundary } from "@sentry/react"
import { useContext } from "react"
import CommentContext from "src/components/Comments/CommentContext"
import { ThreadNotificationData } from "src/components/Notifications/ThreadNotification"
import ThreadNotificationToastContent from "src/components/Notifications/ThreadNotificationToastContent"

const ThreadNotificationToast = ({ notification, onClose, isAdmin }: { notification: ThreadNotificationData, onClose: () => void, isAdmin: boolean }) => {
  const { CommentsProvider, AdminCommentsProvider } = useContext(CommentContext)
  const Provider = isAdmin ? AdminCommentsProvider : CommentsProvider

  return (
    <Provider>
      <ErrorBoundary fallback={<></>}>
        <ClientSideSuspense fallback={<></>}>
          <ThreadNotificationToastContent notification={notification} onClose={onClose} />
        </ClientSideSuspense>
      </ErrorBoundary>
    </Provider>
  )
}

export default ThreadNotificationToast
