import { combineReducers } from '@reduxjs/toolkit'

import auditLogReducer from 'src/slices/auditLog'
import complianceReducer from 'src/slices/compliance'
import dueListTableSlice from 'src/slices/dueListTableSlice'
import eLogbooksFiltersSlice from 'src/slices/eLogbooksFiltersSlice'
import maintenanceItemTableSlice from 'src/slices/maintenanceItemTableSlice'
import modalReducer from 'src/slices/modal'
import workCompletedFiltersSlice from 'src/slices/workCompletedFiltersSlice'

import appLayoutSlice from './slices/appLayoutSlice'
import clerkSlice from './slices/clerk'
import historyCountSlice from './slices/historyCountSlice'
import reportsSlice from './slices/page/reportsSlice'
import workOrdersFiltersSlice from './slices/workOrdersFiltersSlice'
import workOrderSlice from './slices/workOrderSlice'

const rootReducer = combineReducers({
  dueListTable: dueListTableSlice,
  maintenanceItemTable: maintenanceItemTableSlice,
  eLogbookFilters: eLogbooksFiltersSlice,
  workCompletedFilters: workCompletedFiltersSlice,
  modal: modalReducer,
  compliance: complianceReducer,
  workOrdersFilters: workOrdersFiltersSlice,
  workOrder: workOrderSlice,
  clerk: clerkSlice,
  historyCount: historyCountSlice,
  auditLog: auditLogReducer,
  appLayout: appLayoutSlice,
  reports: reportsSlice,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
