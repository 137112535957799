import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Permissions } from '@wingwork/common/src/constants/permissions'

interface SliceState {
  isLoaded: boolean
  permissions: string[]
}

// Initial state
const initialState: SliceState = {
  permissions: [],
  isLoaded: false,
}

const clerkSlice = createSlice({
  name: 'clerk',
  initialState,
  reducers: {
    setClerkData: (
      state,
      action: PayloadAction<{ permissions: string[]; isLoaded?: boolean }>
    ) => {
      const { permissions, isLoaded } = action.payload
      state.permissions = permissions
      state.isLoaded = isLoaded ?? false

      const permissionsArray: string[] = Object.values(Permissions).flatMap(
        Object.values
      )

      permissions.forEach((permission: string) => {
        if (
          !permissionsArray.includes(permission) &&
          process.env.NODE_ENV === 'development'
        ) {
          //TODO: Muting this, needs to be handled properly
          /*console.error(
            `usePermissions hook is not aware of permission: '${permission}', please update the object in usePermissions.ts`
          )*/
        }
      })
    },
    clearClerkData: (state) => {
      state = initialState
    },
  },
})

export const { setClerkData, clearClerkData } = clerkSlice.actions

export default clerkSlice.reducer
