import React, { createContext, useEffect, useState, ReactNode } from 'react'
import { createMongoAbility, MongoAbility } from '@casl/ability'
import { useAuth, useUser } from '@clerk/clerk-react'
import { Permit, permitState } from 'permit-fe-sdk'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import Loading from 'src/components/Loading'

// Create Context
export const AbilityContext = createContext<MongoAbility | undefined>(undefined)

interface AbilityLoaderProps {
  children: ReactNode
  orgSlug: string
}

export const AbilityLoader: React.FC<AbilityLoaderProps> = ({
  children,
  orgSlug,
}) => {
  const { isSignedIn, user } = useUser()
  const userId = user?.id
  const { getToken } = useAuth()
  const [ability, setAbility] = useState<MongoAbility | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getAbility = async () => {
      if (!isSignedIn || !userId) {
        setIsLoading(false)
        return
      }

      setIsLoading(true)

      try {
        const token = await getToken()
        const permit = Permit({
          loggedInUser: userId,
          backendUrl: '/.netlify/functions/checkPermissions',
          customRequestHeaders: {
            orgSlug,
            Authorization: `Bearer ${token}`,
            'auth-provider': 'clerk',
          },
        })
        permit.reset()
        await permit.loadLocalStateBulk([
          ...Object.values(Permissions.compliance),
          ...Object.values(Permissions.maintenanceItem),
          ...Object.values(Permissions.user),
          ...Object.values(Permissions.workOrder),
          ...Object.values(Permissions.purchaseOrder),
        ])

        const caslConfig = permitState?.getCaslJson()

        const newAbility =
          caslConfig && caslConfig.length
            ? createMongoAbility(caslConfig)
            : undefined

        setAbility(newAbility)
      } catch (error) {
        console.error('Error loading permissions:', error)
      } finally {
        setIsLoading(false)
      }
    }

    getAbility()
  }, [isSignedIn, userId, orgSlug])

  if (isLoading) {
    return <Loading />
  }

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  )
}
