import React, { useEffect, useState } from 'react'

// This import will be automatically removed when building for production
import { Button } from '@mui/material'

import { DevFatalErrorPage } from '@redwoodjs/web/dist/components/DevFatalErrorPage'

const ProductionErrorPage = ({ error }) => {
  const showRefreshPrompt = error?.message?.includes(
    'Failed to fetch dynamically imported module'
  )

  const handleRefresh = () => {
    window.location.reload()
  }

  return (
    <main>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            html, body {
              margin: 0;
            }
            html * {
              box-sizing: border-box;
            }
            main {
              display: flex;
              align-items: center;
              font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
              text-align: center;
              background-color: #E2E8F0;
              height: 100vh;
            }
            section {
              background-color: white;
              border-radius: 0.25rem;
              width: 32rem;
              padding: 1rem;
              margin: 0 auto;
              box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
            }
            h1 {
              font-size: 2rem;
              margin: 0 0 1rem;
              font-weight: 500;
              line-height: 1;
              color: #2D3748;
            }
            p {
              margin: 0 0 1rem;
              color: #4A5568;
            }
            button {
              background-color: #4299E1;
              color: white;
              border: none;
              padding: 0.5rem 1rem;
              font-size: 1rem;
              border-radius: 0.25rem;
              cursor: pointer;
              transition: background-color 0.3s ease;
            }
            button:hover {
              background-color: #3182CE;
            }
          `,
        }}
      />
      <section>
        <h1>
          <span>
            {showRefreshPrompt
              ? 'New Version Available'
              : 'Something went wrong'}
          </span>
        </h1>
        {showRefreshPrompt ? (
          <>
            <p>
              A new version of the application is available. Please refresh the
              page to update.
            </p>
            <Button size="large" className="m-2" onClick={handleRefresh}>
              Refresh Page
            </Button>
          </>
        ) : (
          <p>
            We apologize for the inconvenience. Please try again later or
            contact support if the problem persists.
          </p>
        )}
      </section>
    </main>
  )
}

export default DevFatalErrorPage || ProductionErrorPage
