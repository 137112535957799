import { useQuery } from '@redwoodjs/web'
import { FeatureName } from '@wingwork/common/src/constants/features'

export const GET_FEATURE_SETTING = gql`
  query GetFeatureSetting($input: TenantFeatureQueryInput) {
    tenantFeatures(input: $input) {
      featureName
      enabled
    }
  }
`

const useHasFeature = (featureName: FeatureName, orgSlug: string) => {
  const { data, loading } = useQuery(GET_FEATURE_SETTING, {
    variables: { input: { orgSlug } },
    skip: !orgSlug,
    fetchPolicy: 'cache-first',
  })
  const enabledFeatures = data?.tenantFeatures?.filter((feature) => feature.enabled).map((feature) => feature.featureName)

  return { hasFeature: enabledFeatures?.includes(featureName), loading }
}

export default useHasFeature
