import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ELogbookTableFiltersData } from 'src/types'
import { isNotEmpty } from 'src/utils/helpers'

import { reset } from '../sharedActions'

export const initialFilterData: ELogbookTableFiltersData = {
  searchTerm: '',
  aircrafts: {},
}

export interface ELogbookSearchParams {
  searchTerm?: string
  aircrafts?: string
}

export function getELogbookSearchParams(
  filtersData: ELogbookTableFiltersData
): ELogbookSearchParams {
  const { searchTerm, aircrafts: aircraftFilter } = filtersData
  const selectedAircrafts = Object.keys(aircraftFilter).filter(
    (acId) => aircraftFilter[acId]
  )
  return {
    ...(searchTerm && { searchTerm }),
    ...(selectedAircrafts.length > 0 && {
      aircrafts: selectedAircrafts.join(','),
    }),
  }
}

export function areELogbookPrevDepValuesReset(
  prevDeps: ELogbookTableFiltersData
): boolean {
  const { searchTerm: prevSearchTerm, aircrafts: prevAircrafts } = prevDeps

  return isNotEmpty(prevSearchTerm) || isNotEmpty(prevAircrafts)
}

export function updateELogbookPrevDeps(
  filtersData: ELogbookTableFiltersData
): ELogbookTableFiltersData {
  return {
    searchTerm: filtersData.searchTerm,
    aircrafts: filtersData.aircrafts,
  }
}

export function createELogbookFiltersObject(
  searchParams: URLSearchParams
): ELogbookTableFiltersData {
  const getParam = (key) => searchParams.get(key)
  const getParamArray = (key) => getParam(key)?.split(',') || []
  const searchTerm = getParam('searchTerm') || initialFilterData.searchTerm
  const aircrafts = getParam('aircrafts')
    ? Object.fromEntries(getParamArray('aircrafts').map((acId) => [acId, true]))
    : initialFilterData.aircrafts

  return {
    ...initialFilterData,
    searchTerm,
    aircrafts,
  }
}

interface ELogbookFilterSliceState {
  data: ELogbookTableFiltersData
}

const initialState: ELogbookFilterSliceState = {
  data: initialFilterData,
}

const eLogbooksFiltersSlice = createSlice({
  name: 'eLogbooksFilters',
  initialState,
  reducers: {
    setELogbookFiltersData(
      state,
      action: PayloadAction<ELogbookTableFiltersData>
    ): void {
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState)
  },
})

export const { setELogbookFiltersData } = eLogbooksFiltersSlice.actions

export default eLogbooksFiltersSlice.reducer
