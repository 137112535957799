/**
 * Appends the tail number in parentheses to the input text if it is not already included.  The function does so by searching the text directly rather than checking any kind of template that might have generated the text
 * @param text - The text to append the tail number to
 * @param tailNumber - The tail number to append
 * @returns The text with the tail number appended if it is not already included
 */
export const appendTailNumber = (text: string, tailNumber: string) => {
  if (text.includes(tailNumber) || !text || !tailNumber) {
    return text
  }
  return `${text} (${tailNumber})`
}
