import { createLiveblocksContext } from "@liveblocks/react"

export type CommentsProvider = ReturnType<typeof createLiveblocksContext>['LiveblocksProvider']

export const DefaultCommentsProvider = ({ children }: { children: React.ReactNode }) => <>{children}</>

/**
 * Provides two Providers:
 * - CommentsProvider which allows a user to view comments on pages, reply to them, etc. as the logged in user
 * - AdminCommentsProvider which allows a user to view message sent to WingWork Support and reply as WingWork support if they're within the support inbox
 */
const CommentContext = React.createContext<{
  CommentsProvider: CommentsProvider
  AdminCommentsProvider: CommentsProvider
  roomId: string
}>({
  CommentsProvider: DefaultCommentsProvider,
  AdminCommentsProvider: DefaultCommentsProvider,
  roomId: '',
})

export default CommentContext
