import { ThreadData } from '@liveblocks/client'
import { Thread } from '@liveblocks/react-ui'
import CommentThreadWrapper from 'src/components/Comments/CommentThreadWrapper'

const CommentThreadList = ({ threads }: { threads: ThreadData[] }) => {
  return (
    <CommentThreadWrapper>
      {threads.map((thread) => (
        <Thread key={thread.id} thread={thread} />
      ))}
    </CommentThreadWrapper>
  )
}

export default CommentThreadList
