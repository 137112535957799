import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import {
  DueListPrevDeps,
  DueListTableFilterData,
  DuelistFilterProjection,
  SelectedInProgress,
} from 'src/types'

import { reset } from '../sharedActions'
import { MaintenanceItem } from 'types/graphql'
import { isMaintenanceItemInProgress } from 'src/utils/maintenanceItem'

export const initialProjectionFilterData: DuelistFilterProjection = {
  // date: dayjs().format('MM/DD/YYYY'),
  date: undefined,
  isOptional: false,
  isPartBased: false,
  statuses: undefined,
  cadenceType: undefined,
  projectionGrid: [
    {
      id: 1,
      metric: 'Landings',
      dailyUse: 1,
      days: 1,
      override: false,
      projection: 1,
    },
    {
      id: 2,
      metric: 'Hours',
      dailyUse: 1,
      days: 1,
      override: false,
      projection: 1,
    },
    {
      id: 3,
      metric: 'Cycles',
      dailyUse: 1,
      days: 1,
      override: false,
      projection: 1,
    },
    {
      id: 4,
      metric: 'APU Hours',
      dailyUse: 1,
      days: 1,
      override: false,
      projection: 1,
    },
  ],
}

export const initialFilterData: DueListTableFilterData = {
  fuzzySearchTerms: '',
  powerSearchTerms: {},
  aircrafts: {},
  dueStatus: {
    overdue: false,
    inTolerance: false,
    notInDue: false,
  },
  interval: {
    flyingHours: false,
    day: false,
    month: false,
    landing: false,
    takeoff: false,
  },
  assignedMechanic: {},
  timeFrameAll: false,
  timeframeDays: {
    option: '',
    days: -1,
  },
  timeframeHours: {
    hours: -1,
    option: '',
  },
  timeframeCycles: {
    cycles: -1,
    option: '',
  },
  timeframeLandings: {
    landings: -1,
    option: '',
  },
  filterDataBy: [],
  projectionFilterData: initialProjectionFilterData,
  tableRefreshKey: 0,
}

export const initialMaintenanceItemPrevDeps: DueListPrevDeps = {
  fuzzySearchTerms: '',
  powerSearchTerms: {},
  aircrafts: {},
  timeFrameAll: false,
  timeframeDays: { option: '', days: -1 },
  timeframeHours: { option: '', hours: -1 },
  timeframeCycles: { option: '', cycles: -1 },
  timeframeLandings: { option: '', landings: -1 },
  filterDataBy: [],
  projectionFilterData: initialProjectionFilterData,
}
export interface DueListSearchParams {
  activeTimeframeFilter?: string
  fuzzySearchTerms?: any
  powerSearchTerms?: any
  timeFrameAll?: boolean
  filterDataBy?: string
  aircrafts?: string
  option?: string
  days?: number
  landings?: number
  cycles?: number
  hours?: number
}

interface MaintenanceItemTableSliceState {
  filters: DueListTableFilterData
  showChildItems: boolean
  columnsToShow: string[]
  selectedItems: string[]
  selectedInProgressItems: SelectedInProgress
}

const initialState: MaintenanceItemTableSliceState = {
  filters: initialFilterData,
  showChildItems: false,
  columnsToShow: [],
  selectedItems: [],
  selectedInProgressItems: {},
}

const maintenanceItemTableSlice = createSlice({
  name: 'maintenanceItemTable',
  initialState,
  reducers: {
    setMaintenanceItemFiltersData(
      state,
      action: PayloadAction<DueListTableFilterData>
    ): void {
      state.filters = action.payload
    },
    setMaintenanceItemColumnsData: (state, action: PayloadAction<string[]>) => {
      state.columnsToShow = action.payload
    },
    // TODO: make this action-based
    setShowChildMaintenanceItems: (state, action: PayloadAction<boolean>) => {
      state.showChildItems = action.payload
    },
    maintenanceItemSelected(state, action: PayloadAction<MaintenanceItem | MaintenanceItem[]>) {
      const maintenanceItems = Array.isArray(action.payload)
        ? action.payload
        : [action.payload]
      const ids = maintenanceItems.map((item) => item.id)

      const uniqueIds = new Set([...state.selectedItems, ...ids])
      state.selectedItems = Array.from(uniqueIds)

      maintenanceItems.forEach((item) => {
        if (isMaintenanceItemInProgress(item)) {
          state.selectedInProgressItems[item.id] = {
            ataCode: item.ataCode,
            manufactureCode: item.manufactureCode,
            title: item.title,
          }
        }
      })
    },
    maintenanceItemUnselected(
      state,
      action: PayloadAction<MaintenanceItem | MaintenanceItem[]>
    ) {
      const ids = Array.isArray(action.payload)
        ? action.payload.map((item) => item.id)
        : [action.payload.id]
      state.selectedItems = state.selectedItems.filter((id) => !ids.includes(id))

      ids.forEach((id) => {
        delete state.selectedInProgressItems[id]
      })
    },
    unselectAllMaintenanceItems(state) {
      state.selectedItems = []
      state.selectedInProgressItems = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reset, () => initialState)
  },
})

export const {
  setMaintenanceItemFiltersData,
  setMaintenanceItemColumnsData,
  setShowChildMaintenanceItems,
  maintenanceItemSelected,
  maintenanceItemUnselected,
  unselectAllMaintenanceItems,
} = maintenanceItemTableSlice.actions

export default maintenanceItemTableSlice.reducer
