import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface HistoryCountState {
  data: number
}

const initialState: HistoryCountState = {
  data: -1,
}

const historyCountSlice = createSlice({
  name: 'historyCount',
  initialState,
  reducers: {
    incrementHistoryCount: (state) => {
      state.data += 1
    },
    incrementHistoryCountBy: (
      state: HistoryCountState,
      action: PayloadAction<number>
    ) => {
      state.data += action.payload
    },
    // this prevents the duelist from hydrating filters from session, instead hydrates from the URL
    resetHistoryCount: (state) => {
      state.data = initialState.data
    },
  },
})

export const {
  incrementHistoryCount,
  incrementHistoryCountBy,
  resetHistoryCount,
} = historyCountSlice.actions

export default historyCountSlice.reducer
