import { ClientSideSuspense } from "@liveblocks/react/suspense"
import { CircularProgress } from "@mui/material"
import { ErrorBoundary } from "@sentry/react"
import { useContext } from "react"
import CommentsFailedIcon from "src/components/Comments/CommentsFailedIcon"
import CommentContext from "src/components/Comments/CommentContext"
import SupportNotificationPopover from "src/components/Notifications/SupportNotificationPopover"

const SupportIcon = () => {
  const { AdminCommentsProvider } = useContext(CommentContext)
  return (
    <AdminCommentsProvider>
      <ErrorBoundary fallback={<CommentsFailedIcon />}>
        <ClientSideSuspense fallback={<CircularProgress className="p-1" />}>
          <SupportNotificationPopover />
        </ClientSideSuspense>
      </ErrorBoundary>
    </AdminCommentsProvider>
  )
}

export default SupportIcon
