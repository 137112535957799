import CommentsDisabledIcon from '@mui/icons-material/SmsFailedOutlined';
import { IconButton, Tooltip } from '@mui/material';

const CommentsFailedIcon = () => {
  return <Tooltip title="Failed to load comments">
    <span>
      <IconButton
        aria-label="open comments drawer"
        color="inherit"
        disabled
      >
        <CommentsDisabledIcon className="Mui-disabled" />
      </IconButton>
    </span>
  </Tooltip >
}

export default CommentsFailedIcon
