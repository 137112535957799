import { useEffect, useMemo, useRef } from 'react'

import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded'

import { createClient } from '@supabase/supabase-js'
import { Badge, IconButton, Popover, Typography } from '@mui/material'

import { useMutation } from '@redwoodjs/web'
import { useLazyQuery } from '@apollo/client'

import Loading from 'src/components/Loading'
import Button from 'src/components/MUI/Button'
import useQuery from 'src/hooks/useQuery'

import useUserId from 'src/hooks/useUserId'
import { toast } from '@redwoodjs/web/toast'
import { useClient, useInboxNotifications, useMarkInboxNotificationAsRead, useUnreadInboxNotificationsCount } from '@liveblocks/react'
import { InboxNotificationData } from '@liveblocks/client'
import { useOrgName } from 'src/hooks/useOrgName'
import ThreadNotificationToast from 'src/components/Notifications/ThreadNotificationToast'
import { ComplianceLedger, Notification } from 'types/graphql'
import isThreadNotification from 'src/components/Notifications/helpers/isThreadNotification'
import getThreadLink, { GET_COMPLIANCE_LEDGER_STATUS } from 'src/components/Comments/getThreadLink'
import NotificationsList, { CommentNotificationData } from 'src/components/Notifications/NotificationsList'
import getNewNotifications from 'src/components/Notifications/helpers/getNewNotifications'
import useHasFeature from 'src/hooks/useHasFeature'

const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    notifications {
      id
      message
      read
      createdAt
      actionName
      actionPerformed
      link
    }
  }
`

const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation MarkAllNotificationsAsRead {
    markAllNotificationsAsRead {
      id
      read
    }
  }
`

const supabase = createClient(
  process.env.SUPABASE_URL,
  process.env.SUPABASE_ANON_KEY
)

const NotificationPopover = () => {
  const { id: userId, hasLoaded: userIdHasLoaded } = useUserId()
  const orgSlug = useOrgName()
  const { hasFeature: orgHasComments } = useHasFeature('Comments', orgSlug)

  let client: ReturnType<typeof useClient> | undefined
  let markInboxNotificationAsRead: ReturnType<typeof useMarkInboxNotificationAsRead> | undefined
  let allInboxNotifications: (InboxNotificationData & { link?: string })[] | undefined
  let unreadInboxNotificationsCount: number | undefined = 0
  try {
    client = useClient();
    markInboxNotificationAsRead = useMarkInboxNotificationAsRead()
    const useInboxNotificationsResponse = useInboxNotifications();
    const unreadInboxNotificationsCountResponse = useUnreadInboxNotificationsCount()
    unreadInboxNotificationsCount = unreadInboxNotificationsCountResponse.count
    allInboxNotifications = useInboxNotificationsResponse.inboxNotifications
  }
  catch (err) {
  }
  const inboxNotifications: CommentNotificationData[] = useMemo(
    () => orgHasComments
      ? allInboxNotifications
        ?.filter(isThreadNotification)
        ?.filter((notification) => notification.roomId.startsWith(`/${orgSlug}`))
      : undefined,
    [orgHasComments, allInboxNotifications]
  )
  const previousInboxNotifications = useRef(inboxNotifications)

  const [getComplianceLedgerStatus] = useLazyQuery<ComplianceLedger, { id: string }>(GET_COMPLIANCE_LEDGER_STATUS)

  useEffect(() => {
    const processCommentThreadNotifications = async () => {
      // Assign the correct link for each thread, since links might change depending on the status of their attached entity (e.g. a compliance that is now a logbook entry)
      for (let i = 0; i < inboxNotifications.length; i++) {
        const notification = inboxNotifications[i]

        const { room, leave } = client.enterRoom(notification.roomId)
        const { thread } = await room.getThread(notification.threadId)
        leave()

        inboxNotifications[i].link = await getThreadLink(thread, orgSlug, getComplianceLedgerStatus)
      }

      // For any new notifications, show the toast
      if (previousInboxNotifications.current) {
        const newNotifications = getNewNotifications(previousInboxNotifications.current, inboxNotifications)
        for (const notification of newNotifications) {
          toast.custom(
            <ThreadNotificationToast
              notification={notification}
              onClose={() => toast.remove(notification.id)}
              isAdmin={false}
            />, { id: notification.id }) // For some reason, toast.dismiss was not removing the last toast, so I had to use toast.remove
        }
      }

      previousInboxNotifications.current = inboxNotifications
    }
    if (inboxNotifications) {
      processCommentThreadNotifications()
    }
  }, [inboxNotifications, getComplianceLedgerStatus])

  useEffect(() => {
    if (!userIdHasLoaded || !userId) return
    const channel = supabase
      .channel('realtime notifications')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'Notification',
          filter: `userId=eq.${userId}`,
        },
        (payload) => {
          toast(payload.new.message)
          refetch()
        }
      )
      .subscribe()
    return () => {
      supabase.removeChannel(channel)
    }
  }, [supabase, userIdHasLoaded])

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const [markAllNotificationsAsRead] = useMutation(
    MARK_ALL_NOTIFICATIONS_AS_READ
  )

  const { data, hasLoaded, refetch } = useQuery<{ notifications: Notification[] }>(GET_NOTIFICATIONS)
  const notifications = [...data?.notifications ?? [], ...(inboxNotifications ?? [])].sort((a, b) => {
    const notificationTimeA = new Date('createdAt' in a ? a.createdAt : a.notifiedAt)
    const notificationTimeB = new Date('createdAt' in b ? b.createdAt : b.notifiedAt)
    return notificationTimeB.getTime() - notificationTimeA.getTime()
  })

  const unreadSystemNotificationsCount = data?.notifications?.filter((n) => !n.read).length ?? 0
  const unreadNotificationsCount = (unreadInboxNotificationsCount ?? 0) + unreadSystemNotificationsCount

  const handleNotificationsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleNotificationsClose = () => {
    setAnchorEl(null)
  }

  const onMarkAllAsReadClicked = async () => {
    await Promise.all([
      markAllNotificationsAsRead(),
      ...inboxNotifications?.map((n) => markInboxNotificationAsRead(n.id)),
    ])
    refetch()
  }

  return (
    <>
      <IconButton
        aria-label={`show ${unreadNotificationsCount} new notifications`}
        color="inherit"
        onClick={handleNotificationsClick}
      >
        <Badge badgeContent={unreadNotificationsCount} color="error">
          <NotificationsNoneRoundedIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleNotificationsClose}
        classes={{
          paper:
            'flex flex-col rounded-md w-46 max-h-45 mt-0.5 overflow-hidden',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="m-1 flex items-center justify-between">
          <Typography variant="h6">Notifications</Typography>
          <Button
            size="small"
            variant="text"
            onClick={onMarkAllAsReadClicked}
          >
            Mark all as read
          </Button>
        </div>
        {!hasLoaded && (
          <div className="m-4 flex h-full items-center justify-center">
            <Loading />
          </div>
        )}
        {hasLoaded && notifications.length === 0 && (
          // handle no notifications
          <div className="flex h-full items-center justify-center">
            <Typography variant="subtitle2" className="my-4">
              No new notifications
            </Typography>
          </div>
        )}
        {hasLoaded && (
          <NotificationsList
            notifications={notifications}
            refetch={refetch}
            handleNotificationsClose={handleNotificationsClose}
          />
        )}
      </Popover>
    </>
  )
}

export default NotificationPopover
