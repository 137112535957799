import { useInboxNotifications } from "@liveblocks/react"
import { Close } from "@mui/icons-material"
import { styled } from "@mui/material"
import IconButton from "src/components/MUI/IconButton"
import isThreadNotification from "src/components/Notifications/helpers/isThreadNotification"
import ThreadNotification, { ThreadNotificationData } from "src/components/Notifications/ThreadNotification"

const StyledThreadNotificationToast = styled('div')(({ theme }) => ({
  display: 'flex',
  '.lb-root': {
    paddingRight: 25,
  },
  '.lb-inbox-notification-details': {
    display: 'none'
  },
  '.lb-inbox-notification-actions': {
    display: 'none'
  },
}))

const ThreadNotificationToastContent = ({ notification, onClose }: { notification: ThreadNotificationData, onClose: () => void }) => {
  const { inboxNotifications } = useInboxNotifications()
  const inboxNotification = inboxNotifications?.find((n) => n.id === notification.id)

  return inboxNotification && isThreadNotification(inboxNotification) && (
    <StyledThreadNotificationToast className="rounded-md hover:bg-gray-100 shadow-[0_1px_7px_0_rgba(0,0,0,0.35)]">
      <ThreadNotification notification={{ ...inboxNotification, link: notification.link, readAt: new Date() /* Register as read to avoid blue styling */ }} />
      <IconButton className="absolute top-0 right-0" onClick={(e) => {
        e.stopPropagation()
        onClose()
      }}>
        <Close />
      </IconButton>
    </StyledThreadNotificationToast>
  )
}

export default ThreadNotificationToastContent
