import { useEffect } from 'react'

import { useLocation } from '@redwoodjs/router'

import { incrementHistoryCount } from 'src/slices/historyCountSlice'

import { useDispatch } from './useDispatch'

const useHistoryCount = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(incrementHistoryCount())
  }, [location, dispatch])
}

export default useHistoryCount
