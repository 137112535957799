import { styled } from "@mui/material"
import Notification from './Notification'
import { Notification as NotificationType } from 'types/graphql'
import ThreadNotification, { ThreadNotificationData } from "src/components/Notifications/ThreadNotification"

const StyledNotificationDiv = styled('div')(({ theme }) => ({
  '.lb-inbox-notification-date': {
    color: theme.palette.text.lowEmpathy,
    fontWeight: 400,
  },
  '.lb-inbox-notification-title': {
    wordWrap: 'anywhere' // Prevents commnent notifications from overflowing the div
  }
}))

export type CommentNotificationData = ThreadNotificationData & { link?: string }

type NotificationsListProps = {
  notifications: (CommentNotificationData | NotificationType)[]
  refetch: () => void
  handleNotificationsClose: () => void
}

const NotificationsList = ({ notifications, refetch, handleNotificationsClose }: NotificationsListProps) => {
  return (
    <StyledNotificationDiv className="h-full overflow-scroll px-1 py-1 space-y-1">
      {notifications?.map((notification) => (
        <div className="rounded-md hover:bg-gray-100 shadow-[0_1px_4px_0_rgba(0,0,0,0.15)]" key={notification.id}>
          {('roomId' in notification
            ? (
              <ThreadNotification
                key={notification.id}
                notification={notification}
              />
            )
            : (
              <Notification
                key={notification.id}
                notification={notification}
                handleClose={handleNotificationsClose}
                refetch={refetch}
              />
            ))
          }
        </div>
      ))}
    </StyledNotificationDiv>
  )
}

export default NotificationsList
