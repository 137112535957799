import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    new BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        'https://app.wingwork.com',
        'https://dev.wingwork.com',
        'https://demo.wingwork.com',
        'https://staged.wingwork.com',
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [window.location.origin],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

export default Sentry
