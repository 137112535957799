import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ReportsState } from 'src/types'

const initialState: ReportsState = {
  activeReportCategory: 'General',
  activeReport: null,
}

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setActiveReportCategory: (
      state: ReportsState,
      action: PayloadAction<ReportsState['activeReportCategory']>
    ) => {
      state.activeReportCategory = action.payload
    },
    setActiveReport: (
      state: ReportsState,
      action: PayloadAction<ReportsState['activeReport']>
    ) => {
      state.activeReport = action.payload
    },
  },
})

export const { setActiveReport, setActiveReportCategory } = reportsSlice.actions

export default reportsSlice.reducer
