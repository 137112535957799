import { ReactNode } from 'react'

import { Alert, Fade, AlertColor } from '@mui/material'
import {
  Toast as ToastType,
  ValueOrFunction,
  Renderable,
} from 'react-hot-toast'

import { toast } from '@redwoodjs/web/toast'

type CustomToastType = ToastType & {
  action?: ReactNode
}

type ToastProps = {
  t: CustomToastType
  message: ToastType['message']
}

// Mapping react-hot-toast types to MUI Alert severities
const toastTypeToSeverity: { [key: string]: AlertColor } = {
  success: 'success',
  error: 'error',
  loading: 'info',
  blank: 'info',
  custom: 'info',
}

// Helper function to resolve the message
const resolveMessage = (
  message: ValueOrFunction<Renderable, ToastType>,
  t: ToastType
): React.ReactNode => {
  if (typeof message === 'function') {
    return message(t)
  }
  return message
}

const Toast: React.FC<ToastProps> = ({ t, message }) => {
  // Determine the severity based on the toast type
  const severity = toastTypeToSeverity[t.type] || 'info'

  // Resolve the message
  const resolvedMessage = resolveMessage(message, t)

  return (
    <Fade in={t.visible}>
      <Alert
        variant="filled"
        severity={severity}
        onClose={() => toast.dismiss(t.id)}
        action={t?.action}
      >
        {resolvedMessage}
      </Alert>
    </Fade>
  )
}

export default Toast
