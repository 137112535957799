import { datadogLogs } from '@datadog/browser-logs'

// Init Datadog Logs only if the client token is provided
if (process.env.DATADOG_CLIENT_TOKEN) {
  console.log('Initializing Datadog Logs')
  datadogLogs.init({
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    forwardConsoleLogs: 'all',
    env: process.env.NODE_ENV,
  })
  console.log('Datadog Logs initialized')
} else {
  console.log('Datadog client token not provided, defaulting to console')
}

export const logger = process.env.DATADOG_CLIENT_TOKEN
  ? datadogLogs.logger
  : console
