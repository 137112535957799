import Loading from "src/components/Loading";
import CommentsDrawerScaffold from "src/components/Comments/CommentsDrawerScaffold";
import CommentsDrawerHeader from "src/components/Comments/CommentsDrawerHeader";

const LoadingCommentsDrawer = () => {
  return (
    <CommentsDrawerScaffold>
      <CommentsDrawerHeader showResolved={false} setShowResolved={() => { }} showResolvedDisabled />
      <Loading />
    </CommentsDrawerScaffold>
  )
}

export default LoadingCommentsDrawer
