export const isLedgerInProgress = (maintenanceItem) => {
  return maintenanceItem.complianceLedger?.status === 'IN_PROGRESS'
}

export const isRevisionInProgress = (maintenanceItem) => {
  return maintenanceItem.complianceLedger?.isRevision
}

export const isLogbookInProgress = (maintenanceItem) => {
  return (
    maintenanceItem.complianceLedger?.status === 'SIGN' &&
    (maintenanceItem.complianceLedger?.MaintenanceLogbook?.length === 0 ||
      maintenanceItem.complianceLedger?.MaintenanceLogbook?.every(
        (log) => log.status === 'DRAFT'
      ))
  )
}

export const isLogbookAwaitingSignature = (maintenanceItem) => {
  return (
    maintenanceItem.complianceLedger?.status === 'SIGN' &&
    maintenanceItem.complianceLedger?.MaintenanceLogbook?.length > 0 &&
    maintenanceItem.complianceLedger?.MaintenanceLogbook?.every(
      (log) => log.status === 'PUBLISHED'
    )
  )
}

export const isWorkOrderInProgress = (maintenanceItem) => {
  return !!maintenanceItem.workOrderIdInProgress
}

export const isMaintenanceItemInProgress = (maintenanceItem) => {
  return (
    isLedgerInProgress(maintenanceItem) ||
    isRevisionInProgress(maintenanceItem) ||
    isLogbookInProgress(maintenanceItem) ||
    isLogbookAwaitingSignature(maintenanceItem) ||
    isWorkOrderInProgress(maintenanceItem)
  )
}

export const inProgressValues = (maintenanceItem) => {
  return {
    isLedgerInProgress: isLedgerInProgress(maintenanceItem),
    isRevisionInProgress: isRevisionInProgress(maintenanceItem),
    isLogbookInProgress: isLogbookInProgress(maintenanceItem),
    isLogbookAwaitingSignature: isLogbookAwaitingSignature(maintenanceItem),
    isWorkOrderInProgress: isWorkOrderInProgress(maintenanceItem),
    isMaintenanceItemInProgress: isMaintenanceItemInProgress(maintenanceItem),
  }
}
