import {
  IconButton as MUIIconButton,
  IconButtonProps as MUIIconButtonProps,
  Tooltip,
  TooltipProps,
} from '@mui/material'

export type IconButtonProps = MUIIconButtonProps & {
  tooltip?: string
  disabledTooltip?: string
  TooltipProps?: TooltipProps
}

const IconButton: React.FC<IconButtonProps> = ({
  tooltip,
  disabledTooltip,
  TooltipProps,
  disabled,
  ...buttonProps
}) => {
  return (
    <Tooltip
      enterDelay={disabled ? 100 : 700} // fast tooltips for disabled clarity, long delay for regular buttons
      {...TooltipProps}
      title={(disabled && disabledTooltip) || tooltip}
    >
      <span>
        <MUIIconButton disabled={disabled} {...buttonProps} />
      </span>
    </Tooltip>
  )
}

export default IconButton
