import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ComplianceState {
  aircraftUsageLogId: string
  complianceLedgerId: string
  aircraftId: string
}

const initialState: ComplianceState = {
  aircraftUsageLogId: '',
  complianceLedgerId: '',
  aircraftId: '',
}

const complianceSlice = createSlice({
  name: 'compliance',
  initialState,
  reducers: {
    setAircraftUsageLogId: (
      state: ComplianceState,
      action: PayloadAction<string>
    ) => {
      state.aircraftUsageLogId = action.payload
    },
    setComplianceLedgerId: (
      state: ComplianceState,
      action: PayloadAction<string>
    ) => {
      state.complianceLedgerId = action.payload
    },
    setAircraftId: (state: ComplianceState, action: PayloadAction<string>) => {
      state.aircraftId = action.payload
    },
  },
})

export const { setAircraftUsageLogId, setComplianceLedgerId, setAircraftId } =
  complianceSlice.actions

export default complianceSlice.reducer
