import { ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import { toast } from '@redwoodjs/web/toast'

import { setAircraftId } from './slices/auditLog'
import store from './store'
import { findNested } from './utils/helpers'

const CustomApolloLink = (redwoodApolloLinks) => {
  //add aircraftId as header to the request if it is a mutation
  const addAircraftIdLink = new ApolloLink((operation, forward) => {
    operation.setContext((oldContext) => {
      // Check if the operation is a mutation
      const isMutation = operation.query.definitions.some(
        (definition) =>
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'mutation'
      )

      // Only add the aircraft-id header for mutations and if it is not already set like for reviseComplianceLedger
      if (isMutation && !oldContext?.headers?.['aircraft-id']) {
        const aircraftIdFromPayload = findNested(
          operation.variables,
          'aircraftId'
        )
        // from redux store
        const aircraftIdFromRedux = store.getState().auditLog.aircraftId

        // If the aircraftId is in the payload, set it in the redux store
        if (aircraftIdFromPayload && aircraftIdFromRedux === '') {
          store.dispatch(setAircraftId(aircraftIdFromPayload))
        }

        return {
          ...oldContext,
          headers: {
            ...oldContext.headers,
            'aircraft-id': aircraftIdFromPayload ?? aircraftIdFromRedux,
          },
        }
      }

      // For other operations, return the old context
      return oldContext
    })

    return forward(operation)
  })

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )

        const isMutation = operation.query.definitions.some(
          (definition) =>
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'mutation'
        )
        if (isMutation && message.startsWith("You don't have permission to")) {
          toast.error(message)
        }
      })
    if (networkError) {
      // browser is offline check
      if (typeof window !== undefined && !window.navigator.onLine) {
        toast.error('Unable to make request. Check your internet connection.')
      }
      console.error(
        `[Network error]: ${operation.operationName} ${networkError}`
      )
    }
  })

  return ApolloLink.from([
    errorLink,
    addAircraftIdLink,
    ...redwoodApolloLinks.map(({ link }) => link),
  ])
}

export default CustomApolloLink
