import { RoomProvider } from "@liveblocks/react"

import { ClientSideSuspense } from "@liveblocks/react/suspense"
import { CircularProgress } from "@mui/material"
import { ErrorBoundary } from "@sentry/react"
import { useContext } from "react"
import CommentContext from "src/components/Comments/CommentContext"
import CommentIconContent from "src/components/Comments/CommentIconContent"
import CommentsFailedIcon from "src/components/Comments/CommentsFailedIcon"

const CommentIcon = () => {
  const { roomId } = useContext(CommentContext)

  return (
    <ErrorBoundary fallback={<CommentsFailedIcon />}>
      <ClientSideSuspense fallback={<CircularProgress className="p-1" />}>
        <RoomProvider id={roomId}>
          <CommentIconContent />
        </RoomProvider>
      </ClientSideSuspense>
    </ErrorBoundary>
  )
}

export default CommentIcon
